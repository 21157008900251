import React, { useState } from "react";

import "./styles/global/global.scss";

function App() {
  const [base64Image, setBase64Image] = useState(null);

  const handleImageInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64String = e.target.result;
        setBase64Image(base64String);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="App">
      <h1>Image to Base64 Converter</h1>
      <div className="file-input-wrapper">
        <input
          type="file"
          accept="image/*"
          id="file"
          onChange={handleImageInputChange}
        />
        <label htmlFor="file">Select Image</label>
      </div>
      <div className="divider"></div>
      {base64Image && (
        <div>
          <h2>Base64 Image:</h2>
          <div className="output">
            <textarea value={base64Image} rows="10" readOnly />
            <img src={base64Image} alt="Base64" />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;